.background {
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;

  .team-name {
    position: absolute;
    font-size: 4rem;
    letter-spacing: 5rem;
    color: white;
    white-space: nowrap;
    opacity: .2;
    text-transform: uppercase;

    &.one {
      transform-origin: left top;
      transform: rotate(-90deg);
      left: 1%;
      bottom: 12%;
      text-align: left;
    }

    &.two {
      bottom: 1%;
      right: 10%;
      text-align: right;
    }

    &.three {
      top: 12%;
      left: 92.5%;
      text-align: left;
      transform: rotate(90deg);
      transform-origin: left bottom;
    }
  }

  .textureBg {
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }

  &:not(.desktop) {
    .team-name.two {
      display: none;
    }
  }

  &.desktop {
    .team-name {
      font-size: 8rem;
      letter-spacing: 8rem;

      &.one {
        transform-origin: left top;
        transform: rotate(0deg);
        left: 48rem;
        top: 4rem;
        text-align: left;
      }

      &.two {
        bottom: 1%;
        right: 5%;
        text-align: right;
      }

      &.three {
        top: 50%;
        left: 97.5%;
        text-align: left;
        transform: rotate(-90deg);
        transform-origin: left bottom;
      }
    }
  }

  &.landing {
    background-image: url('../assets/landing-bg.jpg');
    background-size: cover;

    .team-name {
      &.one {
        bottom: 70%;
      }
      &.two {
        right: auto;
        left: 9%;
      }
      &.one,
      &.two {
        color: #c1182b; 
      }
      &.three {
        top: .7%;
        left: 30%;
        transform: rotate(0);
        opacity: .1;
      }
    }

    .textureBg {
      background-image: url('../assets/landing-bg-texture-lt.png'), url('../assets/landing-bg-texture-tp.png'), url('../assets/landing-bg-texture-bt.png');
      background-size: auto 100%, 73% auto, 57% auto;
      background-position: left bottom, right top, right bottom;
    }

    &.desktop {
      background-image: url('../assets/landing-bg-desktop.jpg');

      .textureBg {
        background-image: url('../assets/landing-bg-texture-lt-desktop.png'), url('../assets/landing-bg-texture-tp-desktop.png'), url('../assets/landing-bg-texture-bt-desktop.png');
        background-size: auto 49%, 53% auto, 36% auto;
        background-position: left top, center top, right bottom;
      }

      .team-name {
        &.one {
          left: 3rem;
        }

        &.two {
          bottom: 1%;
          right: 5%;
          left: auto;
        }

        &.three {
          top: 50%;
          left: 97.5%;
          transform: rotate(-90deg);
        }
      }
    }
  }

  &.battlehawks {
    background-image: url('../assets/battlehawks-bg.jpg');
    background-size: cover;

    &.desktop {
      background-image: url('../assets/battlehawks-bg-desktop.jpg');
      .textureBg {
        background-image: url('../assets/battlehawks-bg-texture-lt-desktop.png'), url('../assets/battlehawks-bg-texture-tp-desktop.png'), url('../assets/battlehawks-bg-texture-bt-desktop.png');
        background-size: auto 50%, 54% auto, 36% auto;
        background-position: left top, 30rem top, right bottom;
      }
    }
    
    .team-name {
      &.one {
        color: #1012d2; 
      }
    }

    .textureBg {
      background-image: url('../assets/battlehawks-bg-texture-lt.png'), url('../assets/battlehawks-bg-texture-tp.png'), url('../assets/battlehawks-bg-texture-rt.png'), url('../assets/battlehawks-bg-texture-bt.png');
      background-size: auto 30%, 35% auto, auto 30%, 51% auto;
      background-position: left 60%, left top, right 60%, left bottom;
    }
  }

  &.defenders {
    background-image: url('../assets/defenders-bg.jpg');
    background-size: cover;

    &.desktop {
      background-image: url('../assets/defenders-bg-desktop.jpg');

      .textureBg {
        background-image: url('../assets/defenders-bg-texture-lt-desktop.png'), url('../assets/defenders-bg-texture-rt-desktop.png'), url('../assets/defenders-bg-texture-bt-desktop.png');
        background-size: auto 30%, auto 46%, 39% auto;
        background-position: left top, right top, left bottom;
      }
    }

    .team-name {
      &.one,
      &.two {
        color: #c1182b; 
      }
    }

    .textureBg {
      background-image: url('../assets/defenders-bg-texture-lt.png'), url('../assets/defenders-bg-texture-tp.png'), url('../assets/defenders-bg-texture-rt.png'), url('../assets/defenders-bg-texture-bt.png');
      background-size: auto 33%, 56% auto, auto 34%, 66% auto;
      background-position: left 55%, right top, right 60%, left bottom;
    }
  }

  &.dragons {
    background-image: url('../assets/dragons-bg.jpg');
    background-size: cover;

    &.desktop {
      background-image: url('../assets/dragons-bg-desktop.jpg');
      .textureBg {
        background-image: url('../assets/dragons-bg-texture-lt-desktop.png'), url('../assets/dragons-bg-texture-tp-desktop.png'), url('../assets/dragons-bg-texture-bt-desktop.png');
        background-size: auto 54%, 46% auto, 41% auto;
        background-position: left top, right top, left bottom;
      }
    }

    .team-name {
      &.one {
        color: #e93c16; 
      }
      &.three {
        color: #037426;
      }
    }

    .textureBg {
      background-image: url('../assets/dragons-bg-texture-lt.png'), url('../assets/dragons-bg-texture-tp.png'), url('../assets/dragons-bg-texture-rt.png'), url('../assets/dragons-bg-texture-bt.png');
      background-size: auto 47%, 60% auto, auto 56%, 71% auto;
      background-position: left top, right top, right 82%, left bottom;
    }
  }

  &.guardians {
    background-image: url('../assets/guardians-bg.jpg');
    background-size: cover;
    background-position: center center;

    &.desktop {
      background-image: url('../assets/guardians-bg-desktop.jpg');
      .textureBg {
        background-image: url('../assets/guardians-bg-texture-lt-desktop.png'), url('../assets/guardians-bg-texture-tp-desktop.png'), url('../assets/guardians-bg-texture-bt-desktop.png');
        background-size: auto 50%, 54% auto, 36% auto;
        background-position: left top, 30rem top, right bottom;
      }
    }

    .team-name {
      &.one,
      &.two {
        color: #c1182b; 
      }
    }

    .textureBg {
      background-image: url('../assets/guardians-bg-texture-lt.png'), url('../assets/guardians-bg-texture-tp.png'), url('../assets/guardians-bg-texture-rt.png'), url('../assets/guardians-bg-texture-bt.png');
      background-size: auto 30%, 35% auto, auto 30%, 51% auto;
      background-position: left 55%, left top, right 60%, left bottom;
    }
  }

  &.renegades {
    background-image: url('../assets/renegades-bg.jpg');
    background-size:cover;

    &.desktop {
      background-image: url('../assets/renegades-bg-desktop.jpg');

      .textureBg {
        background-image: url('../assets/renegades-bg-texture-lt-desktop.png'), url('../assets/renegades-bg-texture-tp-desktop.png'), url('../assets/renegades-bg-texture-rt-desktop.png');
        background-size: auto 100%, 100% auto, auto 73%;
        background-position: left top, right top, right bottom, left bottom;
      }
    }

    .team-name {
      &.one,
      &.two {
        color: #5f9bcd; 
      }
    }

    .textureBg {
      background-image: url('../assets/renegades-bg-texture-lt.png'), url('../assets/renegades-bg-texture-tp.png'), url('../assets/renegades-bg-texture-rt.png'), url('../assets/renegades-bg-texture-bt.png');
      background-size: auto 64%, 54% auto, auto 64%, 41% auto;
      background-position: left top, right top, right 70%, left bottom;
    }
  }

  &.roughnecks {
    background-image: url('../assets/roughnecks-bg.jpg');
    background-size: cover;

    &.desktop {
      background-image: url('../assets/roughnecks-bg-desktop.jpg');

      .textureBg {
        background-image: url('../assets/roughnecks-bg-texture-lt-desktop.png'), url('../assets/roughnecks-bg-texture-rt-desktop.png'), url('../assets/roughnecks-bg-texture-bt-desktop.png');
        background-size: auto 49%, auto 74%, 43% auto;
        background-position: left top, right top, left bottom;
      }
    }

    .team-name {
      &.one {
        color: #c1182b; 
      }
    }

    .textureBg {
      background-image: url('../assets/roughnecks-bg-texture-lt.png'), url('../assets/roughnecks-bg-texture-tp.png'), url('../assets/roughnecks-bg-texture-rt.png'), url('../assets/roughnecks-bg-texture-bt.png');
      background-size: auto 69%, 63% auto, auto 34%, 51% auto;
      background-position: left top, right top, right 70%, left bottom;
    }
  }

  &.vipers {
    background-image: url('../assets/vipers-bg.jpg');
    background-size: cover;

    &.desktop {
      background-image: url('../assets/vipers-bg-desktop.jpg');
      .textureBg {
        background-image: url('../assets/vipers-bg-texture-lt-desktop.png'), url('../assets/vipers-bg-texture-tp-desktop.png'), url('../assets/vipers-bg-texture-bt-desktop.png');
        background-size: auto 58%, 44% auto, 56% auto;
        background-position: left top, right top, left bottom;
      }
    }
    
    .team-name {
      &.one, 
      &.two {
        color: #ffd200; 
      }
      &.three {
        color: #a0d246;
      }
    }

    .textureBg {
      background-image: url('../assets/vipers-bg-texture-lt.png'), url('../assets/vipers-bg-texture-bt.png'), url('../assets/vipers-bg-texture-rt.png');
      background-size: auto 55%, 51% auto, auto 56%;
      background-position: left top, left bottom, right bottom;
    }
  }

  &.wildcats {
    background-image: url('../assets/wildcats-bg.jpg');
    background-size: cover;

    &.desktop {
      background-image: url('../assets/wildcats-bg-desktop.jpg');

      .textureBg {
        background-image: url('../assets/wildcats-bg-texture-lt-desktop.png'), url('../assets/wildcats-bg-texture-tp-desktop.png'), url('../assets/wildcats-bg-texture-bt-desktop.png');
        background-size: auto 49%, 77% auto, 35% auto;
        background-position: left top, right top, right bottom;
      }
    }
    
    .team-name {
      &.one {
        color: #c1182b; 
      }
      &.two {
        color: #ec7616;
      }
    }

    .textureBg {
      background-image: url('../assets/wildcats-bg-texture-lt.png'), url('../assets/wildcats-bg-texture-tp.png'), url('../assets/wildcats-bg-texture-rt.png');
      background-size: auto 66%, 54% auto, auto 38%;
      background-position: left bottom, left top, right 60%;
    }
  }
}