.share-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-position:center;
  background-size: cover;
  overflow: hidden;
  display: flex;
  align-content: center;
  align-items: center;
  .photo_container {
    background-repeat: no-repeat;
    padding: 15px;
    position: relative;
  }
  .inner_photo {
    width: 35rem;
    border: 1rem solid transparent;
    outline: 1px solid white;
  }
  .content {
    text-align: center;
    width: 100%;
    color: #fff;
    text-transform: uppercase;
  }
  h1 {
    font-size: 15rem;
    transform: skewX(-15deg);
    margin: 0;
    margin-bottom: 5rem;
  }
  p {
    font-size: 8rem;
    max-width: 55rem;
    margin: 0 auto;
    line-height: 1;
    margin-bottom: 5rem;
  }
  .logo {
    width: 40em;
    margin-top: 10rem;
  }
  .sub-line {
    font-size: 6rem;
    transform: skewX(-15deg);
  }
  .close {
    position: absolute;
    cursor: pointer;
    top: 3.5rem;
    right: 4rem;
    width: 8rem;
    height: 8rem;
    fill: #fff;
    filter: drop-shadow(0 0.2rem 0.4rem rgba(0, 0, 0, 0.8))
  }
  .xfl-ball {
    svg {
      width: 22rem;
    }
  }
  @media (orientation: landscape) {
    .inner_photo {
      width: auto;
      height: 35rem;
    }
  }
}