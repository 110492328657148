.team-view {
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  overflow: hidden;
  position: relative;

  h1 {
    margin: 0 auto;
    font-size: 15rem;
    line-height: 14rem;
    text-align: center;
    letter-spacing: 1rem;
    margin-bottom: 8rem;
    text-transform: uppercase;
    max-width: 90rem;
    .this-is {
      height: 0.68em;
      transform: translateY(.025em);
      margin-right: 0.2em;
      &.short-name {
        margin-right: 0;
        transform: translate(.1em, 0.025em);
      }
    }
    span {
      display: inline-block;
      transform: skewX(-14deg);
    }
    &.desktop-header {
      font-size: 25rem;
      line-height: 23rem;
      max-width: 240rem;
      padding: 0 20rem;
    }
  }

  p {
    font-size: 6.8rem;
    line-height: 6.8rem;
    letter-spacing: .25rem;
    text-transform: uppercase;
    margin-top: 8rem;
    margin-bottom: 10rem;
    span {
      display: inline-block;
      transform: skewX(-14deg);
    }
  }

  .league-logo {
    max-width: 32rem;
  }

  .team-logo {
    margin: 0 4rem;
  }

  .center-content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

