html, body, #root, .app {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

html {
  font-size: 0.85vw;
  &.desktop {
    font-size: 0.5vw;
  }
  overflow: hidden;
}

h1 {
  font-weight: 500;
}

a {
  color: white;
}

.desktop-link-wrapper {
  position: absolute;
  bottom: 14rem;
  left: 50%;
  transform: translateX(-50%);
}

.linkContainer {
  text-align: center;
  margin-top: 6rem;
  a {
    color: white;
    font-size: 3.5rem;
    text-decoration: none;
    text-transform: uppercase;
    margin-right: 6rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.instructions {
  font-size: 5rem;
  text-transform: uppercase;
  transform: skewX(-15deg);
  margin-bottom: 10rem;
}

.hide {
  display: none;
}

.warning {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 600px) {
  html {
    font-size: 5.5px;
  }
}

@media (orientation: landscape) {
  html {
    font-size: .48vh;
    &.desktop {
      font-size: .52vh;
    }
  }
}