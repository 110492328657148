.experience {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .camera-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    video, canvas {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .close-button {
    position: absolute;
    cursor: pointer;
    top: 3.5rem;
    right: 4rem;
    width: 8rem;
    height: 8rem;
    fill: #fff;
    filter: drop-shadow(0 0.2rem 0.4rem rgba(0, 0, 0, 0.8))
  }
  .ui {
    .snapshot-button {
      position: absolute;
      bottom: 10vmin;
      left: 50%;
      transform: translateX(-50%);
      width: 15vmin;
      filter: drop-shadow(0 1rem 2rem rgba(0, 0, 0, 0.8))
    }
  }
}