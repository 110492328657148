.landing-page {
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  overflow: hidden;
  position: relative;

  h1 {
    margin: 0;
    font-size: 15rem;
    line-height: 14rem;
    text-align: center;
    letter-spacing: 1rem;
    margin-bottom: 12rem;
    .this-is {
      height: 0.68em;
      transform: translateY(.025em);
      margin-right: 0.2em;
    }
    span {
      display: inline-block;
      transform: skewX(-14deg);
    }
    &.desktop-header {
      font-size: 15rem;
      line-height: 13rem;
    }
  }

  p {
    margin: 0;
    font-size: 9rem;
    line-height: 7.5rem;
    letter-spacing: .25rem;
    text-transform: uppercase;
    margin-top: 16rem;
    margin-bottom: 12rem;
    span {
      display: inline-block;
      transform: skewX(-14deg);
    }
    &.warning {
      margin: 0 auto;
      margin-top: 16rem;
      margin-bottom: 12rem;
      color: rgb(196, 0, 0);
    }
  }

  .slider-slide > img {
    width: auto;
  }
  
  .slider-list {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .center-content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .league-logo {
    max-width: 32rem;
    display: block;
  }

  img {
    margin: 0 auto;
  }

  .instructions-color {
    color: rgb(196, 0, 0);
  }

  .team-button {
    display: inline-block;
    background: #aa0000;
    font-size: 10rem;
    line-height: 10rem;
    letter-spacing: .25rem;
    padding: 3rem 20rem 1rem;
    text-transform: uppercase;
    margin-bottom: 8rem;
    span {
      display: inline-block;
      transform: skewX(-14deg);
    }
  }

  .team-logo {
    height: 30rem;
  }

  .team-name-carousel {
    margin-bottom: 6rem;
  }

  .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    top: 35%;
    z-index: 1000;

    &.right-arrow {
      right: 8%;
      border-width: 3.5rem 0 3.5rem 7rem;
      border-color: transparent transparent transparent #aa0000;
    }

    &.left-arrow {
      left: 8%;
      border-width: 3.5rem 7rem 3.5rem 0;
      border-color: transparent #aa0000 transparent transparent;
    }
  }
}
